import React from 'react';

const fullName = "Tiffany West";

const copyListTiffanyWest = [

  {
    type: "image",
    content: {
      src: '/Deeper-dive-tiffany-west-hbcu-students.png',
      mobileSrc: '/Deeper-dive-tiffany-west-hbcu-students-mobile.png',
      alt: "Tiffany West meets with students from Xavier University of Louisiana",
      caption: "Tiffany West meets with students from Xavier University of Louisiana."
    }
  },
  {
    type: "copy-block",
    content:
      <>
        <h3 class="title tiffany-west">An interview with Tiffany West, Senior Director of Advancing Health and Black Equity at&nbsp;Gilead</h3>
        <p>
          Health equity is defined as everyone having the same opportunities to live their healthiest life.<sup>1,2</sup> Yet, people
          of color, in particular Black/African American people, continue to be disproportionately impacted
          by HIV.<sup>3,4</sup> Compared to other races and ethnicities, Black Americans accounted for a greater proportion
          of new HIV diagnoses and people living with HIV, despite representing only ~13% of the US population.<sup>3-5</sup> These disparities are
          driven, in large part, by social and structural inequities, such as racism, HIV stigma, homophobia, and
          poverty, which impact access to health care.<sup>4,6</sup> Addressing these disparities and advancing health
          equity are critical to helping end the HIV epidemic.<sup>4</sup> However, there is significant work left
          to be done to achieve health equity in HIV.<sup>4</sup>
        </p>
        <p>
          Gilead Sciences has committed to advancing health equity and, through a new team solely dedicated to 
          this work, is doing just that through initiatives such as the Historically Black Colleges and 
          Universities (HBCU) Roadshow. We spoke with Tiffany West, Senior Director of Advancing Health and Black 
          Equity at Gilead, to hear more about the HBCU Roadshow and the importance 
          of addressing health equity to help end the HIV epidemic. 
        </p>
        <p class="question">
            <strong>Tell us more about the HBCU Roadshow, how it came to be and why Gilead is involved.</strong>
        </p>
        <p>
          <em><strong>Tiffany West:</strong></em>
        </p>
        <p>
          Gilead is committed to racial equity and social justice. In 2020, Gilead committed over $10 million to the Racial
          Equity Community Impact Fund to support high-impact organizations working to tackle racial inequities
          affecting Black communities across the United States. One arm of the initiative was focused on social justice, where
          we started with 6 HBCUs and invested in their social justice centers to train the next generation of
          leaders on health equity.<sup>7</sup>
        </p>
        <p>
          In October of 2022, Gilead expanded upon this effort to launch the first HBCU Roadshow, which brought sexual health
          education — including HIV prevention and pre-exposure prophylaxis (PrEP) and breast cancer awareness —
          and career development opportunities to students and community members during Homecoming in 9 cities, engaging
          a total of 11 schools across the US. Stops included Xavier University in Louisiana, Morehouse College,
          Spelman College, Tougaloo College in Mississippi, Claflin University and Shaw University in
          North Carolina, Florida A&M University in Tallahassee, Howard University, Jackson State
          University, and the Bayou Classic in New Orleans.
        </p>
        <p>
          The goal of the HBCU Roadshow is really to focus on increasing equity in
          health outcomes, in particular HIV. Gilead has been committed to helping
          end the HIV epidemic for more than 30 years, but we know it will take
          more than medicine to address the social and structural barriers that contribute
          to disparities in HIV. Black people in the South continue to be
          disproportionately impacted by HIV,<sup>7,8</sup> and compared to all
          people diagnosed with HIV in the US, Black Americans have been less likely
          to be linked to care, retained in care, receive antiretroviral treatment, and
          achieve viral suppression.<sup>10,11</sup> By going to these
          HBCUs, we are meeting people in the community, where they are, and with information
          and resources needed to help advance health equity.
        </p>
        <p>
          <strong>
            Why did you select HBCUs and Homecoming to activate this initiative?
          </strong>
        </p>
        <p>
          HBCUs are a pillar of the Black community in the South. Legislators are graduates of HBCUs, and clinicians 
          who are providing medical care are graduates of HBCUs. The HBCU Roadshow was an opportunity 
          for us to engage about 500,000 people in 9 cities across the US 
          South, where rates of HIV are the highest.<sup>8,9</sup>
        </p>
        <p>
        As an HBCU graduate of Xavier University, I understand the importance of these 
          institutions, what value they have brought to my life as a young person, as 
          well as the value that they continue to bring to emerging Black leaders. It was important 
          for us to meet the Black community where they are, which is largely in the South where 
          the majority of HBCUs are located, and where HIV rates are the highest 
          among African Americans.<sup>8,9,12</sup> I believe advancing health equity starts with these 
          impactful organizations in the South.
        </p>
        <p>
          We chose Homecoming because it is an annual gathering of alumni, current students, and 
          community members, which is really multi- and intergenerational, to celebrate the 
          HBCUs, their legacies, and Black culture.<sup>13</sup> Homecomings have close to half a 
          million attendees across the South at HBCUs each year. It's also an opportunity to engage Black students in 
          health equity, racial equity, and social justice within their communities. So it's really exciting to 
          have these meaningful gatherings that bring together multiple generations of Black Americans to empower 
          them in their own health, advocacy, and careers.
        </p>
        <div class="featured-img">
          <img class="profile" src="/Deeper-dive-tiffany-west.png" alt="Tiffany West, Senior Director of Advancing Health and Black Equity at Gilead"/>
        </div>
      </>
  },

  {/*
    type: "image",
    content: {
      src: '/tiffany-west-profile.png',
      mobileSrc: '/tiffany-west-profile.png',
      alt: "Tiffany West Profile",
      caption: "Tiffany West"
    }
  */},

  {
    type: "copy-block",
    content: 
    <>
      <p class="question">
        One prong of the HBCU Roadshow is HealthySexual<sup>&reg;</sup> programming, which aims to address stigma associated 
        with sex and sexuality, as well as HIV, to empower people to proactively protect their sexual health and understand HIV prevention 
        options. Why is it important to normalize HIV, sex, and sexuality to help advance health equity?
      </p>
      <p>
        Normalizing HIV is critically important to addressing the HIV epidemic in the South, as HIV 
        still has a lot of stigma associated with it, specifically in the South and 
        among the Black community.<sup>8</sup> This stigma is a barrier that may keep people from understanding 
        their risks and taking steps to help protect their sexual health, such as HIV testing, condom use, and, 
        if appropriate, PrEP.<sup>9</sup> The CDC did an analysis in 2016 that said that if current HIV 
        diagnoses rates persist, 1 in 2 Black gay and bisexual men will be diagnosed with HIV in 
        their lifetime.<sup>14</sup> This staggering statistic is a large part of why it is 
        important to normalize conversations around HIV and sexual health, and HealthySexual aims 
        to do just that. It is a platform that normalizes sex, sexuality, and HIV to help address the 
        stigma associated with these topics so people can proactively take control of their sexual health. 
      </p>
      <p class="question">
        Increasingly over the past few years, health equity has been receiving greater focus in public health 
        and specifically in HIV. In fact, the White House’s Office of National AIDS Policy recently shared 
        that one of their top priorities for 2023 is focusing on health equity.<sup>15</sup> Why is addressing health 
        equity critical to helping end the HIV epidemic?
      </p>
      <p>
        If we want to help end the HIV epidemic for everyone, everywhere, we have to think about 
        people who are underserved.<sup>16</sup> Health equity is about ensuring that everyone, no matter who 
        you are, no matter how much money you make, no matter what your address is, has the opportunity to achieve optimal sexual health.<sup>1,2</sup> As a company, Gilead has HIV medications that can help people 
        live longer and healthier lives if taken as prescribed. And we are committed to advancing equitable outcomes 
        among everyone who's at risk for HIV and living with HIV. To do this, we have to have 
        an emphasis on communities of color and people who have been traditionally underserved. And that's 
        the essence of health equity, I believe, in the HIV space. 
      </p>
    </>

  },
  {
    type: "blockquote",
    content: "“Health equity is about ensuring that everyone, no matter who you are, no matter how much money you make, no matter what your address is, has the opportunity to achieve optimal sexual health.”",
    author: fullName
  },
  {
    type: "copy-block",
    content: 
      <>
      <p class="question">
        What change do you hope to see as a result of the HBCU Roadshow, specifically in the HIV space?
      </p>
      <p>
        I actually saw the change that I had hoped to see in real time with 
        the HBCU Roadshow. Prior to the HBCU Roadshow, Xavier University, which is a 
        Catholic school, did not allow condom distribution, and in fact, when I went to 
        school there 20 years ago, some of my classmates chose to 
        spend the majority of their college years in the closet because they didn't feel 
        affirmed. But through the HBCU Roadshow, we were able to bring change to this campus, create safe 
        spaces to talk about sexual health and hand out condoms with a QR code to the HealthySexual website 
        to learn more about sexual health and HIV prevention. There was excitement around this on 
        campus and even an administrator said, “Thank you for making us brave.” And so 
        seeing that impact and change on campuses and in young people, it really is moving.
      </p>
      <p class="question">
        Why is access to healthcare and health information important to addressing health equity?
      </p>
      <p>
        Healthcare access is the foundation of health equity. If you don't have access to healthcare, then 
        you aren't going to be able to achieve equitable health outcomes. You don'
        t have access to screening, you don't have access to diagnostics, and you don't have 
        access to treatment.<sup>1,2,17</sup> I think this is a really critical issue in the South where there 
        hasn't been an expansion of Medicaid, and people of color and people living with HIV 
        are more likely to be uninsured or underinsured, which inhibits their 
        access to care.<sup>18,19</sup>
      </p>
      <p>
        Access to health information is also really important to advancing health equity.<sup>9</sup> A lot of school 
        districts in the South teach abstinence-only education,<sup>20,21</sup> and for many of the HBCU student attendees 
        at our HealthySexual sessions, this was the first time they were exposed to accurate and 
        relevant information about their sexual health. And so I think access to healthcare, access 
        to information, and access to economic opportunity are all aspects of health equity 
        that Gilead is committed to addressing. 
      </p>
      </>,
  },
  {
    type: "blockquote",
    content: "“Healthcare access is the foundation of health equity. If you don't have access to healthcare, then you aren't going to be able to achieve equitable health outcomes.”",
    author: fullName
  },
  {
    type: "copy-block",
    content:
     <>
      <p class="question">
        What’s next for the HBCU Roadshow and Gilead’s efforts to advance health equity?
      </p>
      <p>
        We are working on a year-long strategy for HBCU engagement.
      </p>
      <p>
        HBCUs have the opportunity to be advocacy voices and influence policies that are important to the Black community. So it is about establishing long-term relationships with student health and with the administrators of these schools. I think it's important for us from a health equity standpoint to think more broadly about how we are conditioning the environment for changes we want to see in HIV. How are we engaging Black people before they become Black patients? 
      </p>
      <p>
        So as we move into this next phase of the HBCU Roadshow, there are 3 main goals we will focus on:
      </p>
      <ul>
        <li>
          Our continued engagement with student health centers around HIV testing and the provision of PrEP;
        </li>
        <li>
          Ongoing programs with our Gilead community liaisons on issues related to stigma and sexual health among young people; and
        </li>
        <li>
          Establishing HBCUs as our strategic partner in amplifying issues around HIV advocacy and 
          policy, like decriminalization and access to appropriate therapeutics in the South, which are big problems right now. 
        </li>
      </ul>
      <p class="question">
        What are 1 or 2 learnings from the initial HBCU Roadshow that leaders and 
        organizations should consider when building initiatives and programs to address health equity?
      </p>
      <p>
        Two learnings from the HBCU Roadshow that I think are really important are addressing stigma in HIV 
        and engaging voices from within the community. Addressing HIV-related stigma is critically important 
        as there's still a lot of misinformation around HIV acquisition and prevention in the Black 
        community in particular.<sup>4,9,22</sup> The HBCU Roadshow showed us that engaging people where 
        they are during activities that are important to them can make a difference. 
      </p>
      <p>
        Secondly, we learned that we can't do this work effectively without the community. Through Gilead’s Early 
        Talent and University Relations Program, we were able to have 2 amazing HBCU student interns play an integral part 
        in the planning and execution of the HBCU Roadshow. They had the opportunity to collaborate with us and 
        influence how we implemented our core programs on college campuses. I think it is a best practice and a model for further 
        implementation of community programming to ensure that different, diverse voices, and voices representing that 
        community, have a seat at the table in cocreation. For the community, by the community. 
      </p>
     </>
  }
]


export default copyListTiffanyWest;